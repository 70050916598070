import { ERole } from '@/api/schema';

export const SuperAdminRolesMap = {
  [ERole.ROLE_ADMIN]: 'Admin',
  [ERole.ROLE_OWNER]: 'Owner',
  [ERole.ROLE_FINANCE]: 'Finance',
  [ERole.ROLE_MANAGER]: 'Manager'
};

export const Operation = {
  CREATE: 'create',
  UPDATE: 'update',
  READ: 'read'
};
