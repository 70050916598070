
































































































































































import Vue from 'vue';
import type { PropType } from 'vue';
import { mapState } from 'vuex';
import VStatus from '@/components/VStatus.vue';
import * as vr from '@/helpers/validation';
import { ERole } from '@/plugins/role-permissions/types';
import { Operation } from './SuperAdminRolesHelper';
import { boldHighlight } from '@/helpers/formatString';
import { accounts } from '@/api/SuperAdmin';
import { ResponseObject, SuperAdminUser, AccountItem } from '@/api/schema';
import { statusMap } from '@/views/SuperAdminViews/super-admin-accounts/SuperAdminAccountsView.vue';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

interface TextContents {
  header: string;
  mainButton: string;
  secondButton: string;
}
const defaultTexts = {
  header: '',
  mainButton: '',
  secondButton: 'Cancel'
};

export default {
  name: 'SuperAdminRolesDialog',
  components: { VStatus },
  data(): any {
    return {
      statusMap,
      isLoading: {
        account: false
      },
      searchAccountInput: '',
      formIsValid: true,
      itemData: {},
      showPassword: false,
      accounts: [],
      roles: [
        {
          label: 'ADMIN',
          value: ERole.ROLE_ADMIN
        },
        {
          label: 'FINANCE',
          value: ERole.ROLE_FINANCE
        },
        {
          label: 'MANAGER',
          value: ERole.ROLE_MANAGER
        }
      ]
    };
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    processing: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    item: {
      type: Object as PropType<SuperAdminUser>,
      default: (): SuperAdminUser => ({} as SuperAdminUser)
    },
    operation: {
      type: String as PropType<string>,
      default: (): string => ''
    }
  },
  computed: {
    ...mapState('Onboarding', ['operators']),
    operations(): { [key: string]: string } {
      return Operation;
    },
    isViewModal(): boolean {
      return this.operation === Operation.READ;
    },
    disableEmail(): boolean {
      return [Operation.UPDATE, Operation.READ].includes(this.operation);
    },
    showAccountsSelect(): boolean {
      return this.itemData.role === ERole.ROLE_MANAGER;
    },
    contents(): TextContents {
      switch (this.operation) {
        case 'create':
          return {
            ...defaultTexts,
            header: 'Add account',
            mainButton: 'Create'
          };
        case 'update':
          return {
            ...defaultTexts,
            header: 'Edit',
            mainButton: 'Update'
          };
        case 'read':
          return {
            header: 'View',
            mainButton: 'Done',
            secondButton: 'Edit'
          };
        default:
          return { ...defaultTexts };
      }
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): any {
      return {
        email: [vr.required, vr.isEmail],
        password: [vr.required, (v) => vr.minLength(v, 6)],
        role: [vr.required],
        accounts: [
          vr.required,
          (v) =>
            (v && v.length >= 1) || 'At least one account should be selected.'
        ]
      };
    },
    dataToSend(): Partial<SuperAdminUser> {
      const { role, accounts, login, password, id } = this.itemData;
      return {
        role,
        id,
        ...(this.showAccountsSelect && { accounts }),
        ...(!this.disableEmail && {
          login,
          password
        })
      };
    },
    selectedAccountCount(): number {
      return this.itemData?.accounts?.length || 0;
    },
    isSelectedAllAccount(): boolean {
      return this.selectedAccountCount === this.accounts?.length;
    },
    isSelectedSomeAccount(): boolean {
      return this.selectedAccountCount > 0 && !this.isSelectedAllAccount;
    },
    selectAllIcon(): string {
      if (this.isSelectedAllAccount) return 'mdi-close-box';
      if (this.isSelectedSomeAccount) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    value(newValue: boolean): void {
      if (newValue) {
        this.$refs?.form?.reset?.();
        this.$nextTick(() => {
          this.itemData = { ...this.item };
        });
      }
    },
    showAccountsSelect(show: boolean): void {
      if (show) {
        this.getAccounts();
      }
    }
  },
  methods: {
    boldHighlight,
    getAccountsList(): string[] {
      return (
        this.itemData?.accounts?.map((id: number): string | undefined => {
          const account = this.accounts.find(
            (item: AccountItem) => item.id === id
          );
          return account ? this.getAccountName(account) : undefined;
        }) ?? []
      );
    },
    getAccountName(data: AccountItem): string {
      return data?.brandName || data?.login || '';
    },
    getAccounts(): void {
      this.isLoading.account = true;

      const params = {
        page: 0,
        size: 9999,
        'sort-direction': 'DESC',
        'sort-property': 'status'
      };

      accounts(params)
        .then((response: ResponseObject<AccountItem>): void => {
          this.accounts = [...response.content];
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.isLoading.account = false;
        });
    },
    toggleSelectAll(): void {
      this.$nextTick(() => {
        if (this.isSelectedAllAccount) {
          this.itemData.accounts = [];
        } else {
          Vue.set(this.itemData, 'accounts', [...this.accounts]);
        }
      });
    },
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', this.dataToSend);
        }
      });
    }
  }
};
