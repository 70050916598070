





























































import {
  getSuperAdminUsers,
  deleteSuperAdminUsers,
  createSuperAdminUser,
  updateSuperAdminUser
} from '@/api/SuperAdminRoles';
import SuperAdminRolesDialog from '@/views/SuperAdminViews/super-admin-roles/SuperAdminRolesDialog.vue';
import { SuperAdminRolesMap, Operation } from './SuperAdminRolesHelper';
import { ERole } from '@/api/schema';
import type { SuperAdminUser } from '@/api/schema';

export default {
  name: 'SuperAdminRolesView',
  data(): any {
    return {
      roleDialog: false,
      dialogItem: null,
      dialogOperation: null,
      loadingUsers: false,
      processing: false,
      users: [],
      headers: [
        { text: 'E-mail', value: 'login' },
        { text: 'Access', value: 'role' },
        { text: 'Actions', value: 'actions' }
      ]
    };
  },
  components: {
    SuperAdminRolesDialog
  },
  computed: {
    operation(): { [key: string]: string } {
      return Operation;
    }
  },
  created(): void {
    this.getUsers();
  },
  methods: {
    getUsers(): void {
      this.loadingUsers = true;
      getSuperAdminUsers()
        .then((data: SuperAdminUser[]): void => {
          this.users = data;
        })
        .finally(() => (this.loadingUsers = false));
    },
    deleteUser(id: number): void {
      this.loadingUsers = true;
      deleteSuperAdminUsers(id).then(() => this.getUsers());
    },
    openDialog(operation: string, item?: SuperAdminUser): void {
      this.dialogOperation = operation;
      this.dialogItem = item || null;
      this.roleDialog = true;
    },
    closeDialog(): void {
      this.dialogOperation = null;
      this.dialogData = null;
      this.dialogItem = {};
      this.roleDialog = false;
    },
    getRoleName(role: ERole): string {
      return SuperAdminRolesMap[role] || role;
    },
    getRoleIcon(role: ERole): string | null {
      switch (role) {
        case ERole.ROLE_OWNER:
          return 'mdi-mdi-user-outline';
        case ERole.ROLE_ADMIN:
          return 'mdi-file-edit-outline';
        case ERole.ROLE_FINANCE:
          return 'mdi-trending-up';
        case ERole.ROLE_MANAGER:
          return 'mdi-file-outline';
        default:
          null;
      }
    },
    getRoleIconColor(role: ERole): string | null {
      switch (role) {
        case ERole.ROLE_OWNER:
          return '#2B4BFF';
        case ERole.ROLE_ADMIN:
          return '#FFAB00';
        case ERole.ROLE_FINANCE:
          return '#27AE60';
        case ERole.ROLE_MANAGER:
          return '#03C3EC';
        default:
          null;
      }
    },
    isDisableUserView(role: ERole): boolean {
      return role !== ERole.ROLE_MANAGER;
    },
    formDialogHandler(data: SuperAdminUser): void {
      this.processing = true;
      const handler = data.id ? updateSuperAdminUser : createSuperAdminUser;
      handler(data)
        .then((): any => {
          this.closeDialog();
          this.getUsers();
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
