import { http } from './Connect';
import type { AxiosResponse } from 'axios';
import type {
  SuperAdminUser,
  SuperAdminUserCreateData,
  AccountItem
} from '@/api/schema';

/**
 * Get all superadmin users.
 */
export async function getSuperAdminUsers(): Promise<SuperAdminUser[]> {
  return http
    .get('/api/v1/super-admin')
    .then(({ data }: AxiosResponse): SuperAdminUser[] => data);
}

/**
 * Delete superadmin user.
 * @param adminId superadmin user id.
 */
export async function deleteSuperAdminUsers(adminId: number): Promise<void> {
  return http.delete(`/api/v1/super-admin/${adminId}`);
}

/**
 * Create superadmin user.
 * @param userData new superadmin user data.
 */
export async function createSuperAdminUser(
  userData: SuperAdminUserCreateData
): Promise<any> {
  return http.post('/api/v1/super-admin', userData);
}

/**
 * Update superadmin user.
 * @param userData superadmin user data.
 */
export async function updateSuperAdminUser(
  userData: Partial<SuperAdminUser>
): Promise<void> {
  return http.put(`/api/v1/super-admin/${userData.id}`, userData);
}

/**
 * Get accounts vailable for role.
 */
export async function getAvailableAccounts(): Promise<AccountItem[]> {
  return http
    .get(`/api/v2/super-admin/account/available`)
    .then(({ data }: AxiosResponse<AccountItem[]>): AccountItem[] => data);
}
